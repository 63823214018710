/* eslint-disable no-unused-vars */
import { gtmSendUserId } from "@helpers/analytics";
import { useRouter } from "next/router";
import { useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import { useAuth } from "./useAuth";

export enum LayoutType {
  landing = "landing",
  isAuth = "isAuth",
  withoutAuth = "withoutAuth",
  home = "home",
}

export const usePageLayout = () => {
  const [layoutType, setLayoutType] = useState(LayoutType.landing);

  const { isAuth, loginData, checkingSubscribe } = useAuth();
  const router = useRouter();

  useIsomorphicLayoutEffect(() => {
    if (loginData?.fullName) {
      const fetchUserId = setTimeout(() => {
        gtmSendUserId({
          userId: `${loginData.userId}`,
        });
      }, 1000);

      return () => window.clearTimeout(fetchUserId);
    }
  }, [loginData?.fullName, router]);

  useIsomorphicLayoutEffect(() => {
    switch (router.pathname.split("/")[1]) {
      case "dashboard":
      case "bill-and-payments":
      case "new-ticket":
      case "performance":
      case "projects":
      case "tickets":
        setLayoutType(LayoutType.isAuth);
        break;

      case "login":
      case "lupa-password":
        setLayoutType(LayoutType.withoutAuth);
        break;
      case "":
        setLayoutType(LayoutType.home);
        break;
      default:
        setLayoutType(LayoutType.landing);
        break;
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    switch (layoutType) {
      case LayoutType.isAuth: {
        if (!isAuth) {
          // router.push(ROUTES.HOME());
        }
        break;
      }

      case LayoutType.withoutAuth: {
        if (isAuth) {
          // router.push(ROUTES.DASHBOARD());
        }
        break;
      }

      case LayoutType.home: {
        if (isAuth) {
          checkingSubscribe();
        }
        break;
      }

      default:
        break;
    }
  }, [layoutType, isAuth]);

  return {
    layoutType,
  };
};
