import React from "react";
import { LayoutType, usePageLayout } from "@utils/usePageLayout";
// import HeaderAdmin from "./HeaderAdmin";
// import { HeaderLP } from "./HeaderLP";
import { IHeaderComponentProps, IHeaderLPComponentProps } from "./interface";
import { useAccountContext } from "@contexts/AccountProvider";
import dynamic from "next/dynamic";

const HeaderAdmin = dynamic(() => import("./HeaderAdmin"));
const HeaderLP = dynamic(() => import("./HeaderLP").then(mod => mod.HeaderLP));

const Header: React.FC<IHeaderComponentProps | IHeaderLPComponentProps> = ({ ...props }) => {
  const { layoutType } = usePageLayout();
  const { isAuth, logout: handleLogout } = useAccountContext();

  if (layoutType === LayoutType.isAuth && isAuth) {
    return (
      <HeaderAdmin
        logOut={{ handleLogout, isAuth }}
        setExpand={function (): void {
          throw new Error("Function not implemented.");
        }}
        {...props}
      />
    );
  }

  return <HeaderLP {...props} />;
};

export default Header;
