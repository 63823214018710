import { NextPage, NextPageContext } from "next";
import Image from "next/image";
import { useRouter } from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Box, Grid } from "@mui/material";
import { canonicalLink } from "@helpers/url";
import Header from "@layouts/Main/lib/Header";
import { TProductIconData } from "@layouts/Main/lib/Header/lib/MenuProduct/type";
import { getProductMenu } from "@libraries/api/product-menu/product-menu.repos";
import { IMG_PAGE_404 } from "@config/images";
import Text from "@ds/components/Text";
import Footer from "@layouts/Footer";
import Button from "@ds/components/Button";
import { useTranslation, withTranslation } from "next-i18next";
import { localeKey } from "@constants/locale-key";
import ROUTES from "@config/routes";

export interface IProps404 {
  dataNavbar: Array<TProductIconData>;
}

export async function getStaticProps(context: NextPageContext) {
  try {
    const responseProductMenu = await getProductMenu({ level: 0 }, `${context.locale}`);
    const { data: dataProductMenu } = JSON.parse(JSON.stringify(responseProductMenu));
    return {
      props: {
        ...(await serverSideTranslations(context.locale ?? "id", localeKey)),
        title: "404",
        description: "Halaman Tidak Tersedia",
        canonical: canonicalLink("/404", context.locale),
        dataNavbar: dataProductMenu,
      },
    };
  } catch (error) {
    return {
      props: {
        ...(await serverSideTranslations(context.locale ?? "id", localeKey)),
        title: "404",
        description: "Halaman Tidak Tersedia",
        canonical: canonicalLink("/404", context.locale),
        dataNavbar: [],
      },
    };
  }
}

const PageNotFound: NextPage<IProps404> = ({ dataNavbar }) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const _renderErrorPage = (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={0}
        style={{ minHeight: "calc(100vh - 190px)", backgroundColor: "#FFF", marginTop: "50px" }}
      >
        <Grid item xs={12}>
          <Box textAlign="center">
            <Box>
              <Image src={IMG_PAGE_404} alt="404" width={384} height={320} />
            </Box>
            <Box mt={2}>
              <Text
                variant="h1"
                weight="700"
                color="general-main"
                css={{
                  margin: "12px 0",
                }}
              >
                {t("error404.title")}
              </Text>
            </Box>
            <Box mt={1}>
              <Text variant="body1" color="general-mid">
                {t("error404.subtitle1")}
                <br />
                {t("error404.subtitle2")}
              </Text>
            </Box>
            <Box mt={4}>
              <Button onClick={() => router.replace(ROUTES.HOME())}>
                <Text color="white" weight="600">
                  {t("error404.button")}
                </Text>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Header />
      {_renderErrorPage}
      <Footer bgColor="grey-mid" dataNavbar={dataNavbar} />
    </>
  );
};

export default withTranslation(["common"])(PageNotFound);
